//
// reboot.scss
//


// Forms
label {
  font-weight: $font-weight-medium;
}

// Buttons
button:focus {
  outline: none;
}